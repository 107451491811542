<template>
  <div class="landingPage">
    <template v-if="page">
      <div class="container full featured">
        <div class="wrapper">
          <div class="text">
            <h1>{{ page.title }}</h1>
            <Blocks
              v-if="page.intro"
              :blocks="page.intro"
              type="page"
              element="index"
            />
            <div
              class="flex flex-group"
              v-for="button in page.buttons"
              :key="button._key"
            >
              <a
                :href="button.href"
                :target="button.blank ? '_blank' : '_self'"
                class="btn"
                :class="{ 'btn-w-icon': !button.small }"
              >
                <span v-if="button.small">
                  {{ button.title }}
                </span>
                <div v-else class="text">
                  <h2>{{ button.title }}</h2>
                  <p v-if="button.text">{{ button.text }}</p>
                </div>
                <i class="gn gn-icons arrow-link"></i>
              </a>
            </div>
          </div>
          <div class="image">
            <img src="../../assets/img/frontpage-featured-image-6.webp" />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="wrapper">
          <Blocks :blocks="page.body" type="page" element="index" />
          <div class="grid padding-top">
            <div
              class="col-4 col-md-6 col-sm-12"
              v-for="post in page.posts"
              :key="post._key"
            >
              <div class="badge">
                <div class="top">
                  <router-link
                    :to="{
                      name: post._type === 'post' ? 'post' : 'page',
                      params: { slug: getSlug(post) },
                    }"
                    class="image"
                    :style="{
                      backgroundImage: `url('${getImageUrl(post.mainImage)}')`,
                    }"
                  />
                </div>
                <div class="bottom">
                  <div
                    class="flex flex-group flex-wrap"
                    v-if="
                      (post.categories && post.categories.length > 0) ||
                        (post.sponsor && post.sponsor.info_overview)
                    "
                  >
                    <router-link
                      v-for="cat in post.categories"
                      :to="{ name: 'category', params: { slug: getSlug(cat) } }"
                      :key="cat._key"
                      href=""
                      class="pill"
                      >{{ cat.title }}</router-link
                    >
                    <span
                      v-if="post.sponsor && post.sponsor.info_overview"
                      class="pill"
                    >
                      {{ post.sponsor.info_overview }}
                    </span>
                  </div>
                  <h2>
                    <router-link
                      :to="{
                        name: post._type === 'post' ? 'post' : 'page',
                        params: { slug: getSlug(post) },
                      }"
                      >{{ post.title }}</router-link
                    >
                  </h2>
                  <p v-if="post.intro">
                    <router-link
                      :to="{
                        name: post._type === 'post' ? 'post' : 'page',
                        params: { slug: getSlug(post) },
                      }"
                      >{{ getIntro(post) }}</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sanity from "../../sanity";
import imageUrlBuilder from "@sanity/image-url";
import text from "../../helpers/text";
import substring from "../../helpers/substring";
import slug from "../../helpers/slug";
import Blocks from "../../components/Blocks";
import date from "../../helpers/date";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  components: {
    Blocks,
  },

  data() {
    return {
      loading: false,
      page: null,
      posts: [],
    };
  },

  created() {
    this.fetchIndex();
  },

  mounted() {},

  methods: {
    async fetchIndex() {
      this.loading = true;
      sanity
        .fetch(
          `
                    *[_type == "page" && !(_id in path('drafts.**')) && slug.current == $slug][0]
                    {
                        ...,
                        "posts": *[(_type == "post" || _type == "page") && index && !(_id in path('drafts.**')) && (publishedAt == null || publishedAt < $date)]{
                            ...,
                            "title": @.title,
                            "slug": @.slug,
                            "top": [@.top == true][0],
                            categories[] {
                                ...,
                                "title": @->title,
                                "slug": @->slug
                            },
                            sponsor {
                                ...,
                                "info_overview": @->info_overview,
                            },
                        } | order(top desc, publishedAt desc)[0...6],
                        body[] {
                            ...,
                            _type == 'button' => {
                                ...,
                                "title": @->title,
                                "text": @->text,
                                "href": @->href,
                                "blank": @->blank,
                                "small": @->small,
                            },
                            _type == 'topic' => {
                                ...@->{
                                    ...,
                                    body[]{
                                        ...,
                                        _type == 'button' => {
                                            ...,
                                            "title": @->title,
                                            "text": @->text,
                                            "href": @->href,
                                            "blank": @->blank,
                                            "small": @->small,
                                        },
                                    },
                                },
                                "mainImage": @->mainImage,
                            },
                            _type != 'topic' => @,

                            _type == 'price' => {
                                ...,
                                "title": @->title,
                                "description": @->description,
                                "columns": @->columns,
                                "text": @->text,
                                "link": @->link,
                                "price": @->price,
                                "months": @->months,
                                "monthly": @->monthly,
                            },
                            
                            _type == "alignedImage" => {
                                ...,
                                "title": @->title,
                                "visible": @->visible,
                                "text": @->text,
                                "adjustment": @->adjustment,
                                "background": @->background,
                                "alignment": @->alignment,
                                "alignmentText": @->alignmentText,
                                "alignmentWrapper": @->alignmentWrapper,
                                "span": @->span,
                                "file": @->file,
                                "link": @->link,
                                "wrapper": @->wrapper,
                                "icon": @->icon,
                                ...@-> {
                                    mainImage {
                                        ...,
                                        asset {
                                            ...,
                                            "metadata": @->metadata,
                                        },
                                    },
                                },
                            },
                        },
                        buttons[] {
                            ...,
                            "title": @->title,
                            "text": @->text,
                            "href": @->href,
                            "blank": @->blank,
                            "small": @->small,
                        },
                    }
                `,
          {
            slug: "index",
            date: date.currentFullUTCDate(),
          }
        )
        .then(
          (response) => {
            this.page = response;
          },
          (error) => {
            console.warn(error);
          }
        )
        .then(() => {
          this.loading = false;
        });
    },

    getIntro(post) {
      return substring(text(post.intro), 92);
    },

    getImageUrl(source) {
      return imageBuilder
        .image(source)
        .format("webp")
        .height(1080)
        .url();
    },

    getSlug(element) {
      return slug(element);
    },

    goToTest() {
      return window.open("https://sjekkdinlivsstil.re-start.no/", "_blank");
    },
  },
};
</script>
